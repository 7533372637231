// import { get, post } from './http'
import { get, post, postForm, put, putForm, del, upload } from './request'

var $api = []

// 登录
$api.login = params => get('/api/slm/admin', params)

// 获取物流信息接口

//地址管理-获取国家
$api.getCountries = params => get('/api/api-fe/api-addr/admin/countries', params)

//添加国家
$api.addCountries = params => post('/api/api-fe/api-addr/admin/country-update', params)

//删除国家
$api.delCountries = params => del(`/api/api-fe/api-addr/admin/country-del?id=${params}`)

//地址管理-获取省
$api.getProvinces = params => get('/api/api-fe/api-addr/admin/provinces', params)

//添加省
$api.addProvinces = params => post('/api/api-fe/api-addr/admin/provinces-update', params)

//删除省
$api.delProvinces = params => del(`/api/api-fe/api-addr/admin/province-del?code=${params}`)

//地址管理-获取市
$api.getCity = params => get('/api/api-fe/api-addr/admin/cities', params)

//地址管理-添加/编辑市
$api.addCity = params => post('/api/api-fe/api-addr/admin/cities-update', params)

//删除市
$api.delCity = (params, id) => del(`/api/api-fe/api-addr/admin/city-del?code=${params}&provinceCode=${id}`)

//地址管理-获取区
$api.getArea = params => get('/api/api-fe/api-addr/admin/areas', params)

//地址管理-添加/编辑区
$api.addArea = params => post('/api/api-fe/api-addr/admin/areas-update', params)

//删除区
$api.delArea = (params, id) => del(`/api/api-fe/api-addr/admin/area-del?code=${params}&cityCode=${id}`)

//清除系统缓存
$api.clearConfig = params => put(`/api/api-smc/admin/aca/clear-cache?redis_key=${params}`)

//地址管理-获取省市区
$api.getConcat = params => get('/api/api-fe/api-addr-nl/provinces', params)

//地址管理-新增/编辑城市
$api.setCity = (params) => post('/api/api-fe/api-addr/city-update', params)

//地址管理-新增/编辑县区
$api.setArea = (params) => post('/api/api-fe/api-addr/area-update', params)

//修改个人信息
// $api.setInfo = (gender, params, nickName, password, photo) => post(`/api/api-smc/admin/aus/sou-set?gender=${gender}&id=${params}&nickName=${nickName}&password=${password}&photo=${photo}`)
$api.setInfo = (params) => post('/api/api-smc/admin/aus/sou-set', params)

// 测试
$api.test = params => get('/api/api-smc/api/students', params)

//用户列表
$api.userList = params => get('/api/api-u/admin/user/afp', params)

//用户列表-账号封禁
$api.banList = (params, endTime, cause) => put(`/api/api-u/admin/user/ban?uid=${params}&endTime=${endTime}&cause=${cause}`)

//用户列表-账号注销
$api.alrLogin = (params, cause, effectiveOrder, refundOrder) => post(`/api/api-u/admin/alr/logout?id=${params}&cause=${cause}&effectiveOrder=${effectiveOrder}&refundOrder=${refundOrder}`)

//用户列表-查询评论功能是否封禁
$api.commentBan = params => get(`/api/api-smc/user/comment/ban/${params}`)
//用户列表-封禁用户评论功能
$api.commentJBan = (params) => post(`/api/api-smc/user/comment/ban`, params)

//用户列表-解除封禁用户评论
$api.commentFBan = (params, phone) => del(`/api/api-smc/user/comment/ban/${params}/${phone}`)

//解禁
$api.closeBan = params => putForm(`/api/api-u/admin/user/ban-cancel/${params}`)

//用户详情
$api.detailUser = params => get(`/api/api-u/admin/user/detail/${params}`)

//用户列表-报名人信息
$api.banInfo = params => get(`/api/api-smc/admin/user-contacts/list/${params}`)

//用户列表-报名人信息修改
$api.infoEdit = params => post('/api/api-smc/admin/user-contacts/edit', params)

// 用户列表-报名人信息详情
$api.cbfList = params => get(`/api/api-smc/admin/user-contacts/edit/${params}`)


//用户注销/申请列表
$api.alrList = params => get('/api/api-u/admin/alr/page', params)

//用户申请注销列表-通过拒绝
$api.alrCrl = (confirm, params, cause) => post(`/api/api-u/admin/alr/crl?confirm=${confirm}&id=${params}&cause=${cause}`)

//钱包管理-钱包汇总
$api.summaryList = params => get('/api/api-pay/admin/wallet/summary', params)

//钱包管理-钱包列表
$api.walletList = params => get('/api/api-pay/admin/wallet/wrs', params)

//钱包管理-冻结/解冻
$api.walletfuf = params => post(`/api/api-pay/admin/wallet/fuf/${params}`)

//钱包流水-退手续费
$api.walletCommission = params => post(`/api/api-pay/admin/wallet/refund-rate?id=${params}`)



//钱包列表-绑定账户
$api.walletfbaList = params => get(`/api/api-pay/admin/wallet/fba/${params}`)

//钱包列表-充值/扣除金额
$api.walletwr = (addition, amount, reason, remark, wid) => post(`/api/api-pay/admin/wallet/wr?addition=${addition}&amount=${amount}&reason=${reason}&remark=${remark}&wid=${wid}`)

//钱包列表-后台管理员开通钱包
$api.walletOpen = (params) => post(`/api/api-pay/admin/wallet/cow?phone=${params}`)

//钱包管理-钱包流水
$api.walletwarList = params => get('/api/api-pay/admin/wallet/war', params)

//钱包管理-钱包流水 提现详情
$api.walletwarDetail = params => get(`/api/api-pay/admin/wallet/rwa-detail/${params}`)

//钱包管理-钱包流水-提现通过/拒绝
$api.walletwva = (id, params, applyAmount, balance, remark, title) => post(`/api/api-pay/admin/wallet/wva?id=${id}&adopt=${params}&adopt=${applyAmount}&balance=${balance}&remark=${remark}&title=${title}`)
// $api.walletwva = params => post('/api/api-pay/admin/wallet/wva', params)

//字典列表接口
$api.dictList = params => get('/api/api-smc/admin/dict/list', params)

/* 添加或更新条款、须知、声明 */
$api.addTerms = params => post('/api/api-smc/admin/template/sou', params)

/* 获取条款、须知、声明列表 */
$api.getTerms = params => get('/api/api-smc/admin/template/page', params)

/* 获取系列赛标签*/
$api.getTags = params => get('/api/api-smc/admin/mh-classify/page', params)

//新增系列赛标签
$api.addTags = params => post('/api/api-smc/admin/mh-classify/sou', params)

//删除系列赛标签
$api.delTags = params => del(`/api/api-smc/admin/mh-classify/del/${params}`)

//发布赛事
$api.addEvent = params => post('/api/api-smc/admin/mh/sou', params)

//添加/编辑项目
$api.addEntries = params => post('/api/api-smc/admin/entries/sou', params)

/* 发布赛事 - 添加项目 - 获取报名方式列表 */
$api.getRegistrationWay = params => get('/api/api-smc/admin/dict/list', params)

//[赛事列表]
$api.eventList = params => get('/api/api-smc/admin/mh/page', params)

//赛事列表=>赛事列表导出
$api.exportList = params => get('/api/api-smc/admin/mh/export-data')

//赛事列表=>赛事列表排序
$api.assList = (params, id, self) => post(`/api/api-smc/admin/mh/ass?direction=${params}&id=${id}&self=${self}`)

//赛事列表=>发布或取消发布赛事
$api.publishedCancel = params => post(`/api/api-smc/admin/mh/publish/${params}`)

//赛事列表=>取消赛事或恢复赛事
$api.restoreCancal = (params, id) => post(`/api/api-smc/admin/mh/acm?delay=${params}&id=${id}`)

//赛事列表=>取消延期或赛事延期
$api.delayCancal = (params, id) => post(`/api/api-smc/admin/mh/adm?delay=${params}&id=${id}`)

//赛事列表=>赛事项目详情
$api.entriesInfo = params => get(`/api/api-smc/admin/entries/${params}`)

//赛事列表=>删除赛事
$api.delList = params => del(`/api/api-smc/admin/mh/del/${params}`)

//赛事列表=>生成完赛证书
$api.certEvent = (params, flag) => put(`/api/api-smc/admin/mh/gen-cert/${params}?genAll=${flag}`)

//发布或取消发布赛事
// $api.publish = params => post('/api/admin//publish/{id}', params)

//赛事列表=>获取赛事详情
$api.eventDetail = params => get(`/api/api-smc/admin/mh/detail/${params}`)

//赛事列表=>删除赛事
$api.delEvent = params => del('/api/api-smc/admin/mh/del/2', params)

//赛事列表-赛事项目列表
$api.projectList = params => get('/api/api-smc/admin/entries/page', params)

//赛事列表-赛事项目-表单列表
$api.FormList = (params, id) => get(`/api/api-smc/admin/entries/forms/${params}/${id}`)

//赛事列表-赛事项目-表单列表-表单子value值编辑
$api.FormAdd = params => post('/api/api-smc/admin/entries/forms-value/update', params)

//赛事列表-赛事项目列表=>添加赛事项目
$api.addProject = params => post('/api/api-smc/admin/entries/sou', params)

//赛事列表-赛事项目列表=>增值服务项目 
$api.adsList = params => get('/api/api-smc/admin/ads/list', params)

//增值服务项目（赛事服务项）-列表
$api.adsPage = params => get('/api/api-smc/admin/ads/page', params)

//增值服务项目（赛事服务项）-新增/编辑
$api.addAds = params => post('/api/api-smc/admin/ads/sou', params)

//增值服务详情
$api.adsDetail = params => get(`/api/api-smc/admin/ads/detail/${params}`)

//删除增值服务
$api.delAds = params => del(`/api/api-smc/admin/ads/del/${params}`)

//赛事列表-赛事项目=>新增/编辑增值服务项目
$api.addPage = params => post('/api/api-smc/admin/ads/sou', params)

//赛事列表-赛事项目=>新增/编辑表单项
$api.addForm = params => post('/api/api-smc/admin/d-form/sou', params)

//赛事列表-赛事项目=>获取动态表单详情
$api.detailForm = params => get(`/api/api-smc/admin/d-form/detail/${params}`)

//赛事列表-赛事项目详情
$api.entriesDetail = params => get(`/api/api-smc/admin/entries/detail/${params}`)

//赛事列表-赛事项目报名开关
$api.entriesFlag = params => post(`/api/api-smc/admin/entries/co/${params}`)

//赛事列表-赛事项目删除项目
$api.delEntries = params => post(`/api/api-smc/admin/entries/del/${params}`)

//赛事列表-赛事项目排序
$api.entriesAss = (params, id) => post(`/api/api-smc/admin/entries/ass?direction=${params}&id=${id}`)

//赛事列表-结算/取消结算
$api.entriesAsa = params => post(`/api/api-smc/admin/mh/asa/${params}`)

//获取简单赛事列表--条件查询时用
$api.searchEvent = params => get('/api/api-smc/admin/mh/afsm', params)

//获取简单赛事项目列表--条件查询时用
$api.searchProject = params => get(`/api/api-smc/admin/entries/afse/${params}`)

// 获取赛事预览码
$api.adsPreview = params => get(`/api/api-smc/admin/mh/preview/${params}`)

//报名管理列表
$api.signList = params => get('/api/api-smc/admin/mo-ods/list', params)

//报名管理列表-修改订单状态
$api.editStatusList = (id, params) => put(`/api/api-smc/admin/mo-ods/mos?id=${id}&state=${params}`)

//报名管理-获取赛事订单下的报名用户列表
$api.detailUsers = params => get(`/api/api-smc/admin/mo-ods/users/${params}`)

//报名管理-获取子订单详情
$api.detailSub = params => get('/api/api-smc/admin/mh-ods/u-sub-detail', params)

//报名管理-获取子订单详情修改
$api.editSub = params => post('/api/api-smc/admin/mh-ods/u-update-sub', params)

//报名管理-导入参赛号
$api.importRn = params => upload('/api/api-smc/admin/mo-ods/import-rn', params)

//报名管理-批量更改状态
$api.importRn1 = params => upload('/api/api-smc/admin/mo-ods/import-os', params)

//报名管理-导入抽签
$api.importRn2 = params => upload('/api/api-smc/admin/mo-ods/import-dl', params)

// 报名管理-线下报名导入
$api.importRn3 = params => upload('/api/api-smc/admin/mo-ods/import-ieu', params)

//报名管理-预退款
$api.refund = params => get('/api/api-smc/admin/mh-ods/pre-refund', params)

//报名管理-子订单预退款
$api.refundSub = params => get('/api/api-smc/admin/mh-ods/pre-sub-refund', params)

//报名管理-退款
$api.yesRefund = (odId, amount, refundType, reason, subOdId) => postForm(`/api/api-smc/admin/mh-ods/refund?odId=${odId}&refundAll=true&amount=${amount}&refundType=${refundType}&reason=${reason}&subOdId=${subOdId}`)

//报名管理-子订单退款
$api.yesORefund = (odId, amount, refundType, reason, subOdId) => postForm(`/api/api-smc/admin/mh-ods/refund?odId=${odId}&refundAll=false&amount=${amount}&refundType=${refundType}&reason=${reason}&subOdId=${subOdId}`)

// 报名管理-发短信
$api.sendSms = (params, entryId, matchTitle) => put(`/api/api-smc/admin/mo-ods/sendSms?subOdId=${params}&entryId=${entryId}&matchTitle=${matchTitle}`)

//报名管理-导出各种模版
$api.importTemplate = params => get('/api/api-smc/admin/mo-ods/export-tf', params)

//报名管理-导出并提交
$api.importSubmit = params => get('/api/api-smc/admin/mo-ods/export-submit-data', params)

//报名管理-详情
$api.detailOds = params => get(`/api/api-smc/admin/mo-ods/detail/${params}`)

//报名管理-修改订单状态 
$api.editType = params => post('/api/api-smc/admin/mo-ods/import-os', params)

//报名表单项-列表
$api.dformList = params => get('/api/api-smc/admin/d-form/page', params)

//报名表单项-新增/编辑
$api.addDform = params => post('/api/api-smc/admin/d-form/sou', params)

//报名表单项-校验是否有重复的column
$api.accform = params => get('/api/api-smc/admin/d-form/acc', params)

//报名表单项-排序
$api.assForm = (params, id) => post(`/api/api-smc/admin/d-form/ass?direction=${params}&id=${id}`)

//报名表单项-删除
$api.delForm = params => del(`/api/api-smc/admin/d-form/del/${params}`)

//报名表单项-详情
$api.detailDForm = params => get(`/api/api-smc/admin/d-form/detail/${params}`)

//条款须知列表
$api.templateList = params => get('/api/api-smc/admin/template/page', params)


//条款须知列表-编辑/新增
$api.addTemplate = params => post('/api/api-smc/admin/template/sou', params)

//条款须知列表-详情
$api.detailTemplate = params => get(`/api/api-smc/admin/template/detail/${params}`)

//条款须知-删除
$api.delTemplate = params => del(`/api/api-smc/admin/template/del/${params}`)

//问答须知列表
$api.matchClauseList = params => get('/api/api-smc/admin/matchClause/list', params)

//问答须知列表-编辑/新增
$api.addClause = params => post('/api/api-smc/admin/matchClause/edit', params)

//问答须知列表-详情
$api.detailClause = params => get(`/api/api-smc/admin/matchClause/${params}`)

//问答须知-删除
$api.delClause = params => del(`/api/api-smc/admin/matchClause/del/${params}`)


//协议列表
$api.findsList = params => get('/api/api-smc/admin/app-at/finds', params)

//协议列表-编辑/新增
$api.addFinds = params => post('/api/api-smc/admin/app-at/sou', params)

//协议列表-详情
$api.detailFinds = params => get(`/api/api-smc/admin/app-at/finds/rich-text/${params}`)

//协议列表-删除
$api.delFinds = params => del(`/api/api-smc/admin/app-at/del/${params}`)

//黑名单管理列表
$api.blackList = params => get('/api/api-smc/admin/blacklist/page', params)

//黑名单管理-导入
$api.importBl = params => upload('/api/api-smc/admin/blacklist/import-bl', params)

//黑名单管理-添加
$api.addBlack = params => post('/api/api-smc/admin/blacklist/sou', params)

//黑名单管理-移除
$api.delBlack = params => del(`/api/api-smc/admin/blacklist/del/${params}`)

//邀请码列表
$api.codeList = params => get('/api/api-smc/admin/invite-code/page', params)

//邀请码生成
$api.addCode = params => post('/api/api-smc/admin/invite-code/generate', params)

//邀请码删除
$api.delCode = params => del(`/api/api-smc/admin/invite-code/del?ids=${params}`)

//意见反馈列表
$api.feedbackList = params => get('/api/api-smc/admin/feedback/page', params)

//意见反馈回复
$api.replyFeedback = (params, id) => post(`/api/api-smc/admin/feedback/reply`, params)

//创建单个文件目录
$api.createAlbum = params => post('/api/api-fe/api-file/album/create', params)

//更新目录名称
$api.updateAlbum = params => post('/api/api-fe/api-file/album/update', params)

//删除单个目录
$api.delAlbum = params => del('/api/api-fe/api-file/album/del/{id}', params)

//删除单个文件
$api.delFile = params => del('/api/api-fe/api-file/album/file-del/{id}', params)

//删除多个文件
$api.delFiles = params => postForm(`/api/api-fe/api-file/album/files-del?ids=${params}`)

//获取文件目录里的文件
$api.albums = params => get('/api/api-fe/api-file/albums', params)

//根据商户获取文件目录
$api.mainAlbum = params => get('/api/api-fe/api-file/albums/{id}', params)

//字典管理列表
$api.dictPage = params => get('/api/api-smc/admin/dict/page', params)

//字典管理-添加
$api.addDict = params => post('/api/api-smc/admin/dict/sou', params)

//字典管理-删除
$api.delDict = params => del(`/api/api-smc/admin/dict/del/${params}`)

//菜单管理列表
$api.menuPage = params => get('/api/api-smc/admin/menu/list', params)

//简单菜单列表
$api.menuFsm = params => get('/api/api-smc/admin/menu/fsm', params)

//菜单排序
$api.assMenu = (id, params) => post(`/api/api-smc/admin/menu/ass?id=${id}&direction=${params}`)

//登陆菜单列表
$api.menuLogin = params => get('/api/api-smc/admin/login-sus/menus', params)

//添加主菜单/子菜单
$api.addMenu = params => post('/api/api-smc/admin/menu/sou', params)

//添加菜单权限
$api.addPermission = params => post('/api/api-smc/admin/menu-permission/sou', params)

//菜单管理删除
$api.delMenu = params => del(`/api/api-smc/admin/menu/del/${params}`)

//菜单管理权限列表删除
$api.delPermission = params => del(`/api/api-smc/admin/menu-permission/del/${params}`)

//后台账号管理列表
$api.ausPage = params => get('/api/api-smc/admin/aus/page', params)

//添加后台账号
$api.addAus = params => post('/api/api-smc/admin/aus/sou', params)

//删除后台账号
$api.delAus = params => del(`/api/api-smc/admin/aus/del/${params}`)

//后台账号状态开关
$api.assAus = params => post(`/api/api-smc/admin/aus/eoc/${params}`)

//后台账号详情
$api.detailAus = params => get(`/api/api-smc/admin/aus/detail/${params}`)

//后台账号重置密码
$api.resetPwdAus = params => post(`/api/api-smc/admin/aus/reset-pwd?id=${params}`)

//角色列表
$api.asrPage = params => get('/api/api-smc/admin/asr/page', params)

//添加/编辑角色
$api.addAsr = params => post('/api/api-smc/admin/asr/sou', params)

//删除角色
$api.delAsr = params => del(`/api/api-smc/admin/asr/del/${params}`)

//角色详情
$api.detailAsr = params => get(`/api/api-smc/admin/asr/detail/${params}`)

//举报管理列表
$api.rcPage = params => get('/api/api-smc/admin/rc/page', params)

//举报管理列表/封禁||已读
$api.rcBan = (id, params) => put(`/api/api-smc/admin/rc/ban-read?id=${id}&ban=${params}`)

//推送管理列表
$api.pushPage = params => get('/api/api-smc/admin/push/page', params)

//推送管理-新增
$api.addPush = params => post('/api/api-smc/admin/push/sou', params)

//推送管理-取消推送
$api.cancelPush = params => post(`/api/api-smc/admin/push/cancel/${params}`)

//推送管理-删除推送
$api.delPush = params => post(`/api/api-smc/admin/push/del/${params}`)

//banner列表
$api.bannerPage = params => get('/api/api-smc/admin/banner/page', params)

//新增/编辑banner
$api.addBanner = params => post('/api/api-smc/admin/banner/sou', params)

//banner排序
$api.assBanner = (params, id) => post(`/api/api-smc/admin/banner/ass?direction=${params}&id=${id}`)

//banner删除
$api.delBanner = params => del(`/api/api-smc/admin/banner/del/${params}`)

//banner详情
$api.detailBanner = params => get(`/api/api-smc/admin/banner/detail/${params}`)

//banner开关
$api.ocbBanner = params => post(`/api/api-smc/admin/banner/ocb?id=${params}`)

//报名审核列表
$api.affPage = params => get('/api/api-smc/admin/mo-aff/page', params)

//报名审核列表通过/拒绝
$api.araPage = (params) => post('/api/api-smc/admin/mo-vupr/ara', params)

//报名审核详情
$api.detailAff = params => get(`/api/api-smc/admin/mo-aff/detail/${params}`)

//退款审核
$api.odsPage = params => get('/api/api-smc/admin/mo-ods/rar', params)

//退款审核-通过/拒绝
$api.odsPass = (id, params, cause) => post(`/api/api-smc/admin/mh-ods/refund/rav?odId=${id}&pass=${params}&cause=${cause}`)

//退款详情
$api.odsDetail = (params) => get(`/api/api-smc/admin/mo-ods/rad/${params}`)

//退款详情用户购买的增值服务
$api.odsGa = (params) => get(`/api/api-smc/admin/mo-ods/ga/${params}`)

//修改状态
$api.subState = (id, params) => post(`/api/api-smc/admin/mh-ods/update-sub-state/${id}/${params}`)



//中台赛事审核
$api.otePage = params => get('/api/api-smc/admin/mh-ote/page', params)

//中台赛事审核通过
$api.AcaOte = params => postForm('/api/api-smc/admin/mh-ote/aca', params)

//中台赛事审核拒绝
$api.AraOte = params => postForm('/api/api-smc/admin/mh-ote/ara', params)

//中台赛事详情
$api.detailOte = params => get(`/api/api-smc/admin/mh-ote/detail/${params}`)

//新闻栏目列表
$api.amsList = params => get('/api/api-smc/admin/ams/list', params)

//新闻栏目新增/编辑
$api.amsAdd = params => post('/api/api-smc/admin/ams/sou', params)

//新闻栏目删除
$api.delAms = params => del(`/api/api-smc/admin/ams/del/${params}`)

//新闻内容列表
$api.articleList = params => get('/api/api-smc/admin/article/page', params)

//新闻列表
$api.eventNews = params => get('/api/api-smc/admin/article/simple-page', params)

//新闻评论状态开关
$api.aeccfArticle = params => post(`/api/api-smc/admin/article/aeccf?id=${params}`)

//添加新闻
$api.amsArticle = params => post('/api/api-smc/admin/article/sou', params)

//新闻前端是否可展示
$api.asdArticle = params => post(`/api/api-smc/admin/article/asd?id=${params}`)

//新闻删除
$api.delArticle = params => del(`/api/api-smc/admin/article/del/${params}`)

//新闻排序
$api.assArticle = (params, direction) => post(`/api/api-smc/admin/article/ass?id=${params}&direction=${direction}`)

//新闻详情
$api.detailArticle = params => get(`/api/api-smc/admin/article/detail/${params}`)

/* 上传图片 */
$api.fileUpload = params => upload('/api/api-fe/api-file/upload/aliyun', params)

// 逆地址解析 - 测试
$api.geo = params => get('https://restapi.amap.com/v3/geocode/geo', params)

// 上传图片
$api.upload = params => upload('https://gateway.qoqq.com/api-f/files/upload', params)

//播报管理-配置
$api.broadcastConfig = params => get('/api/api-smc/admin/bcc/config', params)

//播报管理-新增配置
$api.broadcastConfigAdd = params => post('/api/api-smc/admin/bcc/sou', params)

//播报管理-删除配置
$api.broadcastConfigDel = params => del(`/api/api-smc/admin/bcc/adc/${params}`)

//播报管理-广播信息
$api.broadcast = params => get('/api/api-smc/admin/bcr/records', params)

//播报管理-广播信息删除
$api.broadcastDel = params => del(`/api/api-smc/admin/bcr/adc/${params}`)

//播报管理-广播信息添加
$api.broadcastAdd = params => post('/api/api-smc/admin/bcr/sou', params)

//订单中心
$api.orderList = params => get('/api/api-pay/admin/unified-order/ors', params)

// 赛事问答
$api.questionAnswerList = params => get('/api/api-smc/admin/match/questionAnswer/list', params)

// 赛事问答详情
$api.questionAnswerDetail = params => get(`/api/api-smc/admin/match/questionAnswer/${params}`)

// 赛事问答状态开关
$api.questionAnswerState = params => put(`/api/api-smc/admin/match/questionAnswer/setState/${params}`)

// 赛事问答删除
$api.questionAnswerDel = params => del(`/api/api-smc/admin/match/questionAnswer/del/${params}`)

// 赛事问答-新增
$api.questionAnswerAdd = params => post('/api/api-smc/admin/match/questionAnswer/edit', params)


//问答列表
$api.qasList = params => get('/api/api-smc/admin/qas/list', params)

//问答-新增
$api.qasAdd = params => post('/api/api-smc/admin/qas/sou', params)

//问答-删除
$api.qasDel = params => del(`/api/api-smc/admin/qas/del/${params}`)

//合作组委会
$api.organizingCommittee = params => get('/api/api-smc/admin/aca/list', params)

//添加合作组委会
$api.organizingAdd = params => post('/api/api-smc/admin/aca/sou', params)

//合作组委会-封禁/解禁
$api.organizingDel = params => post(`/api/api-smc/admin/aca/ban?id=${params}`)


//线上赛赛事管理
$api.eventXList = params => get('/api/api-smc/admin/line-match/page', params)

//线上赛简单赛事管理
$api.eventXSearch = params => get('/api/api-smc/admin/line-match/afsm', params)

//线上赛赛事报名开关	
$api.eventSwitch = params => put(`/api/api-smc/admin/line-match/com/${params}`)

//线上赛赛事发布隐藏开关	
$api.eventPublish = params => put(`/api/api-smc/admin/line-match/apm/${params}`)

//线上赛赛事重新生成完赛证书	
$api.eventGenCert = params => put(`/api/api-smc/admin/line-match/gen-cert/${params}`)

// 线上赛报名管理单人生成完赛证书
$api.eventGenCertLine = params => get(`/api/api-smc/ui-m/v1/line-mh/gen-cert/${params}`)

//赛事列表=>一键完赛
$api.eventCert = params => put(`/api/api-smc/admin/line-match/click-verify/${params}`)


//线上赛赛事详情
$api.eventXDetail = params => get(`/api/api-smc/admin/line-match/detail/${params}`)

//线上赛赛事获取项目
$api.eventXEntries = params => get(`/api/api-smc/admin/line-match/entries/${params}`)

//线上赛赛事套餐纪念品详情
$api.eventXMatchDetail = params => get(`/api/api-smc/admin/line-match/kc-detail/${params}`)

//线上赛修改套餐纪念品
$api.eventXMatchAdd = params => post('/api/api-smc/admin/line-match/kc-detail/sou', params)

//线上赛修改/添加赛事
$api.eventXAdd = params => post('/api/api-smc/admin/line-match/sou', params)

//获取线上赛预览码
$api.previewEvent = params => get(`/api/api-smc/admin/line-match/preview/${params}`)

//线上赛简单获取纪念品列表
$api.keepsakeGks = params => get('/api/api-smc/admin/line-match-keepsake/attrs/gks', params)

//线上赛纪念品
$api.keepsakeList = params => get('/api/api-smc/admin/line-match-keepsake/page', params)

//线上赛添加/修改纪念品
$api.keepsakeAdd = params => post('/api/api-smc/admin/line-match-keepsake/sou', params)

//线上赛纪念品详情
$api.keepsakeDetail = params => get(`/api/api-smc/admin/line-match-keepsake/detail/${params}`)

//线上赛纪念品删除
$api.keepsakeDel = params => del(`/api/api-smc/admin/line-match-keepsake/del/${params}`)

//线上赛纪念品恢复
$api.keepsakePut = params => put(`/api/api-smc/admin/line-match-keepsake/restore/${params}`)

// 线上赛纪念品属性添加/修改
$api.keepsakeMatchAdd = params => post('/api/api-smc/admin/line-match-keepsake/attrs/sou', params)

//线上赛纪念品属性详情
$api.keepsakeMatchDetail = params => get(`/api/api-smc/admin/line-match-keepsake/attrs/${params}`)

//线上赛报名管理
$api.matchList = params => get('/api/api-smc/admin/line-match/enroll/page', params)

//线上赛报名管理-手动完赛
$api.matchFinish = (id, params) => put(`/api/api-smc/admin/line-match/enroll/fm-finish/${id}/${params}`)

//线上赛报名管理-取消成绩
$api.matchCancel = id => put(`/api/api-smc/admin/line-match/enroll/cancel-finish/${id}`)

//线上赛审核成绩
$api.matchVerify = (id, params) => put(`/api/api-smc/admin/line-match/enroll/score-verify/${id}/${params}`)

//线上赛订单管理
$api.orderXList = params => get('/api/api-smc/admin/line-match/order/page', params)

//线上赛订单详情
$api.orderXDetail = id => get(`/api/api-smc/admin/line-match/order/detail/${id}`)

//线上赛纪念品订单退款
$api.orderXRefund = (id, params) => put(`/api/api-smc/admin/line-match/order/refund?id=${id}&refund_amount=${params}`)

//线上赛纪念品订单发货
$api.orderXSend = (id, logistics_code, logistics_name, logistics_no, mid) => put(`/api/api-smc/admin/line-match/order/send?id=${id}&logistics_code=${logistics_code}&logistics_name=${logistics_name}&logistics_no=${logistics_no}&mid=${mid}`)

//线上赛纪念品查看流转信息
$api.orderXLogistics = (id) => get(`/api/api-smc/admin/line-match-keepsake/logistics/${id}`)

//线上赛纪念品订单状态修改
$api.orderXStatus = (id, params) => put(`/api/api-smc/admin/line-match/order/modify-state?id=${id}&state=${params}`)

//线上赛纪念品订单备注
$api.orderXNotes = (params) => post(`/api/api-smc/admin/line-match/order/notes`, params)

//线上赛纪念品修改收件人信息
$api.orderXMra = (params) => post(`/api/api-smc/admin/line-match/order/mra`, params)

//线上赛纪念品批量发货
$api.orderXImport = params => upload('/api/api-smc/admin/line-match/import-so', params)

//活动审核
$api.orderVerify = (params) => put('/api/api-smc/admin-nl/line-match-active/order/verify', params)

//活动测评信息
$api.orderVerifyInfo = (id) => get(`/api/api-smc/admin/line-match/order/evaluation/${id}`)

// 修改活动报名人信息
$api.orderEditInfo = (params) => post(`/api/api-smc/admin/line-match/order/evaluation/update`, params)

// 修改线上赛报名人信息
$api.orderEditXInfo = (params) => post(`/api/api-smc/admin/line-match/enroll/update`, params)

// 获取活动forms表单
$api.orderEditForms = (params) => get(`/api/api-smc/admin/line-match/enroll/evaluation/forms`, params)

// 获取线上赛forms表单
$api.orderEditXForms = (params) => get(`/api/api-smc/admin/line-match/enroll/forms`, params)



//报名表单项-列表
$api.dformListX = params => get('/api/api-smc/admin/line-match/d-form/page', params)

//报名表单项-新增/编辑
$api.addDformX = params => post('/api/api-smc/admin/line-match/d-form/sou', params)

//报名表单项-校验是否有重复的column
$api.accformX = params => get('/api/api-smc/admin/line-match/d-form/acc', params)

//报名表单项-排序
$api.assFormX = (params, id) => post(`/api/api-smc/admin/line-match/d-form/ass?direction=${params}&id=${id}`)

//报名表单项-删除
$api.delFormX = params => del(`/api/api-smc/admin/line-match/d-form/del/${params}`)

//报名表单项-详情
$api.detailDFormX = params => get(`/api/api-smc/admin/line-match/d-form/detail/${params}`)


//创建相册/编辑相册属性
$api.creatAlbum = (params, title, desc) => post(`/api/api-smc/admin/album/create`, params)

//相册列表
$api.albumList = params => get('/api/api-smc/admin/album/page', params)

//相册删除
$api.albumDel = params => del(`/api/api-smc/admin/album/del/${params}`)

//照片列表
$api.photosList = params => get('/api/api-smc/admin/album/photos', params)

//照片删除
$api.photoDel = params => del(`/api/api-smc/admin/album-photo/del/?ids=${params}`)

//上传照片
$api.photoAdd = params => upload('/api/api-smc/admin/album-photo/upload', params)

//上传完成后修改照片信息
$api.photoInfo = params => post(`/api/api-smc/admin/album-photo/rename`, params)

//将相片移动到其他相册
$api.photoMove = (params, ids) => put(`/api/api-smc/admin/album-photo/mta?aid=${params}&ids=${ids}`)

//文件下载管理
$api.fileList = params => get('/api/api-smc/admin/files/page', params)

//上传文件
$api.fileAdd = params => upload('/api/api-smc/admin/files/upload', params)

//删除文件
$api.fileDel = params => del(`/api/api-smc/admin/files/del/${params}`)

//成绩列表
$api.scoreList = params => get('/api/api-smc/admin/score/page', params)

//成绩-导入
$api.scoreRn = params => upload('/api/api-smc/admin/score/import', params)

//成绩-详情
$api.scoreDetail = params => get(`/api/api-smc/admin/score/detail/${params}`)

//取消成绩
$api.scoreCancel = (params, direction) => post(`/api/api-smc/admin/score/cancel/${params}`)

//编辑成绩
$api.scoreEdit = params => post('/api/api-smc/admin/score/sou', params)


//商品列表
$api.goodsList = params => get('/api/api-smc/admin/goods/page', params)

//商品属性详情
$api.goodsMatchDetail = params => get(`/api/api-smc/admin/mh/goods/classify-attrs/${params}`)

// 商品属性添加/修改
$api.goodsMatchAdd = params => post('/api/api-smc/admin/mh/goods/classify-attrs/update', params)

//商品详情
$api.goodsLDetail = params => get(`/api/api-smc/admin/mh/goods/${params}`)

//修改商品状态
$api.goodsState = (params, state) => put(`/api/api-smc/admin/mh/goods/modify-state?id=${params}&state=${state}`)

//修改/添加商品
$api.goodsAdd = params => post('/api/api-smc/admin/mh/goods/sou', params)


//获取简单商品列表
$api.goodsSearch = params => get('/api/api-smc/admin/mh/goods/simples', params)


//商品订单管理
$api.orderGList = params => get('/api/api-smc/admin/goods-order/page', params)
//商品订单状态修改
$api.goodsStatus = (id, params) => put(`/api/api-smc/admin/goods/order/modify-state?id=${id}&state=${params}`)
//商品批量发货
$api.goodsImport = params => upload('/api/api-smc/admin/goods/order/import-so', params)
//商品订单详情
$api.goodsDetail = id => get(`/api/api-smc/admin/goods-order/detail/${id}`)
//商品订单商品列表
$api.goodsChildDetail = id => get(`/api/api-smc/admin/goods-order/goods/${id}`)
//商品订单售后详情
$api.goodsSalesDetail = id => get(`/api/api-smc/admin/goods-order/after-sales/${id}`)
//商品订单售后通过/拒绝
$api.goodsAfter = (id, params) => get(`/api/api-smc/admin/goods-order/after-sales-process/${id}/${params}`)
//商品订单退款
$api.goodsRefund = (id, params) => put(`/api/api-smc/admin/goods/order/refund?id=${id}&refund_amount=${params}`)
//商品订单发货
$api.goodsSend = (id, logistics_code, logistics_name, logistics_no, mid) => put(`/api/api-smc/admin/goods-order/send?id=${id}&logistics_code=${logistics_code}&logistics_name=${logistics_name}&logistics_no=${logistics_no}&mid=${mid}`)
//商品订单查看流转信息
$api.goodsLogistics = (id) => get(`/api/api-smc/ui-m/v1/goods/logistics/${id}`)
//商品订单修改收件人信息
$api.goodsMra = (params) => post(`/api/api-smc/admin/goods/order/mra`, params)

//商品订单备注
$api.goodsNotes = (params) => post(`/api/api-smc/admin/goods/order/remark`, params)

//商品获取全局配置
$api.configDetail = params => get('/api/api-smc/admin/goods-order/config', params)
//商品修改全局配置
$api.configEdit = params => post('/api/api-smc/admin/goods-order/config-sou', params)

// 团购商品列表
$api.groupList = params => get('/api/api-smc/admin/groupGoods/page', params)
// 新增/编辑团购商品
$api.groupEdit = params => post('/api/api-smc/admin/groupGoods/sou', params)
// 删除团购商品
$api.groupDel = params => del(`/api/api-smc/admin/groupGoods/delete?id=${params}`)
// 团购商品显示状态
$api.groupDis = (id, params) => put(`/api/api-smc/admin/groupGoods/modify-display?id=${id}&display=${params}`)

// 获取售后配置
$api.afterSales = params => get('/api/api-smc/admin/mh/goods/afterSales', params)
// 添加或更新未发货退款配置
$api.noneRefund = params => post('/api/api-smc/admin/mh/goods/afterSales/noneRefund/sou', params)
// 添加或更新退货退款配置
$api.returnRefund = params => post('/api/api-smc/admin/mh/goods/afterSales/returnRefund/sou', params)
// 添加或更新换货配置
$api.exchange = params => post('/api/api-smc/admin/mh/goods/afterSales/exchange/sou', params)

// 获取商品订单退货退款售后申请详情
$api.salesReturnRefund = params => get(`/api/api-smc/admin/goods-order/after-sales/returnRefund/${params}`)
// 后台管理员处理售后
$api.salesProcessRefund = (params, type, remark) => get(`/api/api-smc/admin/goods-order/after-sales-process/${params}/${type}`, remark)

// 退换货物流签收
$api.refundExchangeReceiveSign = params => post('/api/api-smc/admin/goods-order/refundExchangeReceiveSign', params)

// 商品售后发货/修改物流
$api.afterSalesSend = (id, logistics_code, logistics_name, logistics_no, send, type) => put(`/api/api-smc/admin/goods-order/afterSales/send?id=${id}&logistics_code=${logistics_code}&logistics_name=${logistics_name}&logistics_no=${logistics_no}&send=${send}&type=${type}`)

// 商品售后查看流转信息
$api.afterSalesLogistics = params => get(`/api/api-smc/ui-m/v2/goods/logistics`, params)



//赛事评论管理
$api.commentPage = params => get('/api/api-smc/admin/mh-comment/page', params)

// 赛事评论屏蔽 / 取消屏蔽
$api.commentShow = (id) => get(`/api/api-smc/admin/mh-comment/show-set/${id}`)

// 新闻评论管理
$api.commentNewPage = params => get('/api/api-smc/admin/article-comment/page', params)

// 新闻评论屏蔽 / 取消屏蔽
$api.commentNewShow = (id) => get(`/api/api-smc/admin/article-comment/show-set/${id}`)


// 总览-数据总览
// $api.overviewBase = params => get('/api/api-smc/admin/overview-quick/base-data', params)

// 总览-数据总览-支付数据
$api.overviewPayment = params => get('/api/api-smc/admin/overview-quick/payment-data', params)

// 总览-数据总览-报名来源数据
$api.overviewForm = params => get('/api/api-smc/admin/overview-quick/froms-data', params)

// 总览-赛事情况
$api.overviewMatch = params => get('/api/api-smc/admin/overview-quick/match-outline', params)

//总览-赛事统计--赛事点击量
$api.overviewHits = params => get('/api/api-smc/admin/overview-quick/match-hits', params)

//总览-赛事统计--赛事性别报名分布
$api.overviewGender = params => get('/api/api-smc/admin/overview-quick/match-gender', params)

//总览-赛事统计--赛事年龄报名分布
$api.overviewAge = params => get('/api/api-smc/admin/overview-quick/match-age', params)

//总览-赛事统计--赛事用户报名分布
$api.overviewPeople = params => get('/api/api-smc/admin/overview-quick/match-entries', params)

//日志管理
$api.logList = params => get('/api/api-smc/admin/logs/page', params)

//签到记录管理
$api.signInList = params => get('/api/api-smc/admin/sign-in/page', params)

//金币账号管理
$api.goldList = params => get('/api/api-smc/admin/sign-in/config/page', params)

//金币账号管理-关闭/恢复
$api.goldIsClose = (params) => post(`/api/api-smc/admin/sign-in/config/open-close`, params)

//金币账号详情
$api.goldDetail = params => get('/api/api-smc/admin/sign-in/gc/page', params)

//金币增加/扣除
$api.goldIsAdd = params => post('/api/api-smc/admin/sign-in/gc/agc', params)

//版本管理
$api.iterationList = params => get('/api/api-smc/admin/app-ver/finds', params)

//版本管理-详情
$api.iterationDetail = params => get(`/api/api-smc/admin/app-ver/detail/${params}`)

//版本管理-编辑
$api.iterationAdd = params => post('/api/api-smc/admin/app-ver/sou', params)

//版本管理-删除
$api.iterationDel = params => del(`/api/api-smc/admin/app-ver/del/${params}`)

//短信管理
$api.messageList = params => get('/api/api-nc/admin/notify/sms/fp', params)

$api.addMsg = params => post('/api/api-nc/admin/notify/sms/apply', params)

//大屏幕看板管理-意见反馈
$api.feedbacksList = params => get('/api/api-smc/admin/board/feedback', params)

//大屏幕看板管理-意见反馈删除
$api.feedbacksDel = params => del(`/api/api-smc/admin/board/feedback/del/${params}`)

//大屏幕看板管理-意见反馈添加
$api.feedbacksAdd = params => post('/api/api-smc/admin/board/feedback/edit', params)



//大屏幕看板管理-需求
$api.jobList = params => get('/api/api-smc/admin/board/job', params)

//大屏幕看板管理-需求删除
$api.jobDel = params => del(`/api/api-smc/admin/board/job/del/${params}`)

//大屏幕看板管理-需求添加
$api.jobAdd = params => post('/api/api-smc/admin/board/job/edit', params)

export { $api }
